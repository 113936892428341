<template>
  <div id='page' class="container">
    <h3>{{data.title}}</h3>
    <div v-html='data.content'></div>
  </div>
</template>
<script>
import { getPageData } from '@/api'
export default {
  data() {
    return {
      data: {}
    }
  },
  computed: {
    articleId() {
      return this.$route.params.id
    }
  },
  watch: {
    articleId() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('setAppLoading', true)
      const params = { articleId: this.articleId }
      getPageData(params).then(res => {
        this.data = res.data.data
        this.$store.dispatch('setAppLoading', false)
      })
    }
  }
}

</script>
<style lang="less">
#page {
  min-height: 500px;

  >h3 {
    margin: 50px 0 20px
  }
}

</style>
